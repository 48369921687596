import React, { useState, useContext } from "react";
import { GlobalDataContext } from "../../../../context/context";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Data from "../../../../api/data.json";

const imgUrl = require.context("../../../../img/", true);
const Customers = () => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="brand-area pt-80 pb-50">
        <div className="container">
          <h1 className="text-center">Our Customers & Partners</h1>
          <div className="row">
            <div className="col-lg-3 col-md-6 text-center content-directorios pb-50">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/A-LOGOS%2FCzarnosqui.png?alt=media&token=1405c1f2-39d2-4583-b714-7ab084e2b673"
                style={{ width: "70%" }}
              />
            </div>
            <div className="col-lg-3 col-md-6 text-center content-directorios">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/A-LOGOS%2FDJM.png?alt=media&token=36124abb-b24f-4bd3-9d00-e401aee4ea38"
                style={{ width: "70%" }}
              />
            </div>
            <div className="col-lg-3 col-md-6 text-center content-directorios">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/A-LOGOS%2FExpressOilChange.png?alt=media&token=95d128d1-c068-49c6-8dbe-c5350bd08df6"
                style={{ width: "70%" }}
              />
            </div>
            <div className="col-lg-3 col-md-6 text-center content-directorios">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/A-LOGOS%2FGSA.png?alt=media&token=9e86fc86-021a-4f43-a53a-85e0b0bee71a"
                style={{ width: "70%" }}
              />
            </div>
            <div className="col-lg-3 col-md-6 text-center content-directorios">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/A-LOGOS%2FItalianIce.png?alt=media&token=03c1d463-9897-473d-8533-d8bf65cd96eb"
                style={{ width: "70%" }}
              />
            </div>
            <div className="col-lg-3 col-md-6 text-center content-directorios">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/A-LOGOS%2FJENSEN.png?alt=media&token=7faefdad-0c9c-40ec-94a3-fcaa651ac105"
                style={{ width: "70%" }}
              />
            </div>
            <div className="col-lg-3 col-md-6 text-center content-directorios">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/A-LOGOS%2FUSArmyCorps.png?alt=media&token=11dabf96-4454-4774-8f57-42ca0b17f7cf"
                style={{ width: "70%" }}
              />
            </div>
            <div className="col-lg-3 col-md-6 text-center content-directorios">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/A-LOGOS%2FUSDepartamento.jpg?alt=media&token=6667d04b-04ca-409a-bed3-ddd019c5c03f"
                style={{ width: "70%" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Customers;
